import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CrmCard, type CrmHistorico } from "../interfaces/card";
import { HttpClientService } from "src/app/providers/services/http-client.service";
import type { PageDatesRequest } from "src/app/providers/models/pageDatesRequest";
import type { StageEnum } from "../enums/stages-enum";

@Injectable({ providedIn: 'root' })
export class CrmService {
    cardsSubject = new BehaviorSubject<CrmCard[]>([]);
    cards$ = this.cardsSubject.asObservable();

    constructor(private httpClient: HttpClientService) {}

    async getAllLeads(): Promise<CrmCard[]> {
        return this.httpClient.get(`/Crm`, "/all");
    }

    async getAllLeadsByUsers(userId: number): Promise<CrmCard[]> {
        return this.httpClient.get(`/Crm`, `/all/sellers/${userId}`);
    }

    async getLeadById(cardId: number): Promise<CrmCard> {
        return this.httpClient.get(`/Crm`, `/lead/${cardId}`);
    }

    async getAllLeadsByDates(
        pageDatesRequest: PageDatesRequest
    ): Promise<CrmCard[]> {
        return this.httpClient.post(`/Crm`, `/leads/datas`, pageDatesRequest);
    }

    async registerLead(
        crmCard: CrmCard,
        usuarioLogadoId: number
    ): Promise<CrmCard> {
        return this.httpClient.post(`/Crm`, `/cadastro/${usuarioLogadoId}`, crmCard);
    }

    async registerHistoricalLead(
        crmHistorico: CrmHistorico,
        usuarioLogadoId: number
    ): Promise<CrmHistorico> {
        return this.httpClient.post(
            `/Crm`,
            `/novo/historico/${usuarioLogadoId}`,
            crmHistorico
        );
    }

    async transferLead(
        crmCard: CrmCard,
        usuarioLogadoId: number
    ): Promise<CrmCard> {
        return this.httpClient.put(`/Crm`, `/transferir-lead/${usuarioLogadoId}`, crmCard);
    }

    async updateMovimentLead(
        crmCardId: number,
        stageEnum: StageEnum,
        usuarioLogadoId: number
    ): Promise<CrmCard> {
        return this.httpClient.put(`/Crm`, `/movimentar/card/${crmCardId}/${stageEnum}/${usuarioLogadoId}`, '');
    }

    async updateLead(
        crmCard: CrmCard,
        usuarioLogadoId: number
    ): Promise<CrmCard> {
        return this.httpClient.put(`/Crm`, `/atualizar-card/${usuarioLogadoId}`, crmCard);
    }

    async deleteLead(cardId: number, usuarioLogadoId: number): Promise<any> {
        return this.httpClient.delete(`/Crm`, `/${cardId}/${usuarioLogadoId}`);
    }

    // saveToSessionStorage(newCard: CrmCard) {
    //     const savedData = sessionStorage.getItem("cardsData");
    //     const currentCards = savedData ? JSON.parse(savedData) : [];
    //     currentCards.push(newCard);
    //     sessionStorage.setItem("cardsData", JSON.stringify(currentCards));
    //     this.cardsSubject.next(currentCards);
    // }

    // updateCardStatus(cardId: number, newStatus: StageEnum) {
    //     const currentCards = this.cardsSubject.value;
    //     const updatedCards = currentCards.map((card) =>
    //         card.id === cardId ? { ...card, status: newStatus } : card
    //     );
    //     this.cardsSubject.next(updatedCards);
    //     sessionStorage.setItem("cardsData", JSON.stringify(updatedCards));
    // }

    // addCard(card: CrmCard) {
    //     this.saveToSessionStorage(card);
    // }

    // updateCardInfo(cardId: number, updatedInfo: Partial<CrmCard>) {
    //     const currentCards = this.cardsSubject.value;
    //     const updatedCards = currentCards.map((card) =>
    //         card.id === cardId ? { ...card, ...updatedInfo } : card
    //     );
    //     this.cardsSubject.next(updatedCards);
    //     this.saveToSessionStoragItens(updatedCards);
    // }

    // saveToSessionStoragItens(data: CrmCard[]) {
    //     sessionStorage.setItem("cardsData", JSON.stringify(data));
    // }
}
