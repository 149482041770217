import { Injectable } from "@angular/core";
import { ContactCellPhonePutRequest } from "../models/users/contact-cellphone-put-request";
import { ContactEmailPutRequest } from "../models/users/contact-email-put-request";
import { ForgotPasswordRequest } from "../models/users/forgot-password-request";
import { LoginRequest } from "../models/users/login-request";
import { PageResponse } from "../providers/models/page-response";
import { resetLoginRequest } from "../providers/models/resetLoginRequest";
import { Usuario } from "../providers/models/usuario";
import { UsuarioLogin } from "../providers/models/usuarioLogin";
import { HttpClientService } from "../providers/services/http-client.service";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private httpClient: HttpClientService) {}

    async userLogin(
        request: LoginRequest
    ): Promise<PageResponse<UsuarioLogin>> {
        return this.httpClient.post(`/UsuarioLogin`, "/login", request, false);
    }

    async checkEmailIfExists(email: string): Promise<boolean> {
        return this.httpClient.get(
            `/Usuario`,
            `/check-email/${btoa(email)}`,
            false
        );
    }

    async checkCellPhoneIfExists(cellPhone: string): Promise<boolean> {
        return this.httpClient.get(
            `/Usuario`,
            `/check-cellphone/${cellPhone}`,
            false
        );
    }

    async checkDocumentIfExists(document: string): Promise<boolean> {
        return this.httpClient.get(
            `/Usuario`,
            `/check-document/${document}`,
            false
        );
    }

    public updatePasswordUser(
        email: string,
        newPassword: string
    ): Promise<Usuario> {
        return this.httpClient.put(
            "/Usuario",
            `/atualizar/senha/usuario/${email}/${newPassword}`,
            ""
        );
    }

    public validUser(resetLogin: resetLoginRequest): Promise<boolean> {
        return this.httpClient.post(
            "/Usuario",
            "/validar/dados/usuario",
            resetLogin
        );
    }

    async updateContactEmail(request: ContactEmailPutRequest): Promise<void> {
        return this.httpClient.put(`/Usuario`, "/contact", request, false);
    }

    async updateContactCellPhone(
        request: ContactCellPhonePutRequest
    ): Promise<void> {
        return this.httpClient.put(
            `/Usuario`,
            "/contact/verify",
            request,
            false
        );
    }

    async getAllUsers(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", "");
    }

    public getSellers(): Promise<Usuario[]> {
        return this.httpClient.get(`/Usuario`, `/vendedores`);
    }

    public getSellersInternal(): Promise<Usuario[]> {
        return this.httpClient.get(`/Usuario`, `/vendedores-lista-internos`);
    }

    public getTechnicalOperators(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/operadores-tecnicos`);
    }

    public getIndicators(): Promise<Usuario[]> {
        return this.httpClient.get(`/Usuario`, `/todos/indicadores`);
    }

    async getUserId(userId: number): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/${userId}`);
    }

    public verifyEmail(email: string): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/email/${email}`);
    }

    public verifyCpf(cpf: string): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/cpf/${cpf}`);
    }

    public getSellersReports(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/vendedores`);
    }

    public getUserByName(usuarioNome: string): Promise<Usuario> {
        return this.httpClient.get(`/Usuario`, `/nome/${usuarioNome}`);
    }

    public getUserByNameSurname(
        usuarioNomeSobrenome: string
    ): Promise<Usuario> {
        return this.httpClient.get(
            `/Usuario`,
            `/nome-sobrenome/${usuarioNomeSobrenome}`
        );
    }

    async userUpdateProfile(usuario: Usuario): Promise<Usuario> {
        return this.httpClient.put(
            `/Usuario`,
            `/atualizar-usuario-profile`,
            usuario
        );
    }

    public saveImage(
        usuarioId: number,
        usuarioLogadoId: number,
        file: File
    ): Promise<Usuario> {
        const fileToUpload = file as File;
        const formData = new FormData();
        formData.append("file", fileToUpload);

        return this.httpClient.post(
            `/Usuario`,
            `/upload-image/${usuarioId}/${usuarioLogadoId}`,
            formData
        );
    }

    public resendEmailUserRegistration(usuarioId: number): Promise<boolean> {
        return this.httpClient.post(
            `/Usuario`,
            `/reenvio/email/cadastro/${usuarioId}`,
            true
        );
    }

    public putChangeUserStatus(
        usuario: Usuario,
        status: boolean,
        usuarioLogadoId: number
    ): Promise<Usuario> {
        return this.httpClient.put(
            `/Usuario`,
            `/atualizar-status/${status}/${usuarioLogadoId}`,
            usuario
        );
    }

    public postUser(usuario: Usuario): Promise<Usuario> {
        return this.httpClient.post("/Usuario", "", usuario);
    }

    public putUser(
        usuario: Usuario,
        usuarioLogadoId: number
    ): Promise<Usuario> {
        return this.httpClient.put(
            "/Usuario",
            `/atualizar-usuario/${usuario.id}/${usuarioLogadoId}`,
            usuario
        );
    }
}
