import { Injectable } from '@angular/core';
import { Banco } from '../providers/models/banco';
import { HttpClientService } from '../providers/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class BancoService {
  constructor(private httpClient: HttpClientService) { }

  public getBancos(): Promise<Banco[]>{
    return this.httpClient.get('/Banco', '');
  }  

  public getBancoPorId(bancoId: number): Promise<Banco>{
    return this.httpClient.get('/Banco', `/banco/id/${bancoId}`);
  }

  public getBancoPorNome(nomeBanco: string): Promise<Banco>{
    return this.httpClient.get('/Banco', `/banco/nome/banco/${nomeBanco}`);
  }

  public postBanco(veiculo: Banco): Promise<Banco> {
    return this.httpClient.post('/Banco', '', veiculo);
  }

  public putBanco(veiculo: Banco, usuarioLogadoId: number): Promise<Banco> {
    return this.httpClient.put('/Banco', `/${veiculo.id}/${usuarioLogadoId}`, veiculo);
  }
  
  public deleteBanco(bancoId: number, usuarioLogadoId: number): Promise<any> {
    return this.httpClient.delete('/Banco', `/id/${bancoId}/${usuarioLogadoId}`);
  } 
}