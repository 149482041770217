import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly contextService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.contextService.isLoggedIn()) {
      return true;
    } else {
      this.urlFromEmail();
      this.router.navigateByUrl('/autenticacao');
    }
    return false;
  }

  urlFromEmail() { // Salva URL para usuários que vierem do Email.
    let url = window.location.pathname;
    sessionStorage.setItem('urlFromEmail', url);
  }
}
