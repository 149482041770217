import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SelectItem, MessageService } from "primeng/api";
import { ProfileEnum } from "src/app/providers/Enums/profile-Enum";
import { AuthService } from "src/app/services/auth.service";
import { ConvertValues } from "src/app/services/convert-values.service";
import { ProjectClientService } from "src/app/services/project-client.service";
import { ProjetoCliente } from "src/app/providers/models/projeto-cliente";
import {
    ProjectTypeEnum,
    ProjectTypeEnumLabel,
} from "src/app/providers/Enums/tipo-projeto-Enum";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
    selector: "app-project-client-list",
    templateUrl: "./project-client-list.component.html",
    styleUrls: ["./project-client-list.component.scss"],
})
export class ProjectClientListComponent implements OnInit {
    projects: ProjetoCliente[] = [];
    returnedArray: ProjetoCliente[];
    clientsOptions: SelectItem[] = [];
    first: number = 0;
    rows: number = 8;
    project = {} as ProjetoCliente;
    profile: number = 0;
    clientName: string = "";
    userLoggedName: string = "";
    visible: boolean = false;
    visibleDelete: boolean = false;
    obsProjectClient: string = "";
    cols: any[];

    constructor(
        private readonly messageService: MessageService,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly spinnerService: SpinnerService,
        private readonly projectClientService: ProjectClientService
    ) {
        this.profile = +this.authService.perfil();

        if (this.profile == ProfileEnum.Indicador) window.history.back();

        this.userLoggedName = this.authService.userFullName();
    }

    async ngOnInit() {
        await this.getProjects();

        this.cols = [
            { field: "codigo", header: "Código", class: "table-cell" },
            { field: "dataCadastro", header: "Cadastro", class: "table-cell" },
            { field: "cliente", header: "Cliente", class: "table-cell" },
            {
                field: "tipoProjeto",
                header: "Tipo Projeto",
                class: "table-cell",
            },
            { field: "acoes", header: "Ações", class: "table-cell" },
        ];
    }

    isAdminOrGeranteProjetos() {
        return (
            this.profile == ProfileEnum.Administrador ||
            this.profile == ProfileEnum.GerenteDeProjetos
        );
    }

    noTecnicoOrOperador() {
        return (
            this.profile != ProfileEnum.Tecnico &&
            this.profile != ProfileEnum.Operador
        );
    }

    isTecnicoOrOperador() {
        return (
            this.profile == ProfileEnum.Tecnico ||
            this.profile == ProfileEnum.Operador
        );
    }

    isAdminOrGeranteSuporte() {
        return (
            this.profile == ProfileEnum.Administrador ||
            this.profile == ProfileEnum.GerenteDeSuporte
        );
    }

    isAdmin() {
        return this.profile == ProfileEnum.Administrador;
    }

    isGeranteEstoque() {
        return this.profile == ProfileEnum.GerenteDeEstoque;
    }

    async getProjects() {
        this.spinnerService.show();
        if (this.isTecnicoOrOperador()) {
            await this.projectClientService
                .getProjectsClientsOperatorName(this.userLoggedName)
                .then(
                    (result) => {
                        this.projects = result;

                        this.setClientsOptions(result);

                        this.projects.sort((a, b) => {
                            if (a.status < b.status) return 1;
                            if (a.status > b.status) return -1;
                            return 0;
                        });

                        this.returnedArray = this.projects.slice(0, 8);
                    },
                    (error: any) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Erro ao tentar listar os Projetos: ${error.message}.`,
                            life: 5000,
                        });
                    }
                )
                .finally(() => this.spinnerService.hide());
        } else {
            await this.projectClientService
                .getProjectsClients()
                .then(
                    (result) => {
                        this.projects = result;

                        this.setClientsOptions(this.projects);

                        this.projects.sort((a, b) => {
                            if (a.status < b.status) return 1;
                            if (a.status > b.status) return -1;
                            return 0;
                        });

                        this.returnedArray = this.projects.slice(0, 8);
                    },
                    (error: any) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Erro ao tentar listar os Projetos: ${error.message}.`,
                            life: 5000,
                        });
                    }
                )
                .finally(() => this.spinnerService.hide());
        }
    }

    setClientsOptions(projects: ProjetoCliente[]) {
        const uniqueNames = new Set<string>();

        projects.forEach((element) => {
            if (!uniqueNames.has(element.clienteNome.toUpperCase())) {
                uniqueNames.add(element.clienteNome.toUpperCase());
                this.clientsOptions.push({
                    label:
                        element.id + " - " + element.clienteNome.toUpperCase(),
                    value: element.clienteNome.toUpperCase(),
                });
            }
        });
    }

    getProjectType(tipoProjeto: ProjectTypeEnum): string {
        return ProjectTypeEnumLabel.get(tipoProjeto)!;
    }

    async deleteProject() {
        this.spinnerService.show();
        await this.projectClientService
            .deleteProjetoCliente(this.project.id, this.authService.userId())
            .then(
                (retorno: any) => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `${retorno.message}.`,
                        life: 5000,
                    });
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar deletar Projeto do Cliente ${this.clientName}`,
                        life: 5000,
                    });
                }
            )
            .finally(() => {
                this.spinnerService.hide();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            });
    }

    detailProject(projectId: number) {
        this.router.navigateByUrl(`/app/projeto-cliente/editar/${projectId}`);
    }

    viewProjectObservation(projeto: ProjetoCliente) {
        this.visible = true;
        this.clientName = projeto.clienteNome.toUpperCase();
        this.obsProjectClient = projeto.observacao;
    }

    showDialogDelete(projeto: ProjetoCliente) {
        this.clientName = ConvertValues.convertNameCapitalize(
            projeto.clienteNome
        );
        this.project = projeto;
        this.visibleDelete = true;
    }

    async buscarProjectsClientByNameClient(event: any) {
        if (event.value != undefined) {
            if (event.value.length > 4 || event.value == "") {
                this.spinnerService.show();
                await this.projectClientService
                    .getProjectsClientsByClientName(event.value.trim())
                    .then((result) => {
                        if (result.length != 0) {
                            this.projects = result;
                            this.returnedArray = this.projects.slice(0, 8);
                        } else {
                            this.messageService.add({
                                severity: "warn",
                                summary: "Atenção!",
                                detail: `Cliente: ${event.value.toUpperCase()} sem projetos cadastrados.`,
                                life: 5000,
                            });
                        }
                    })
                    .finally(() => this.spinnerService.hide());
            } else {
                this.getProjects();
            }
        }
    }

    clearSearch() {
        this.clientName = "";
        this.getProjects();
    }

    async download() {
        this.spinnerService.show();
        var createdAt = new Date();
        var createdAtformatter = createdAt.toLocaleDateString();
        this.messageService.add({
            severity: "info",
            summary: "Aguarde!",
            detail: `Iniciando baixa de relatório.`,
            life: 5000,
        });

        let filename = `Relatório Projeto Cliente - ${createdAtformatter}.xlsx`;
        await this.projectClientService
            .allListDownload()
            .then((result) => {
                this.handleFile(result, filename);
            })
            .catch((error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro!",
                    detail: `Erro ao tentar baixar relatório: ${error.message}.`,
                    life: 5000,
                });
            })
            .finally(() => {
                this.spinnerService.hide();
                setTimeout(() => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `Baixa de relatório efetuada com sucesso.`,
                        life: 5000,
                    });
                }, 2000);
            });
    }

    handleFile(response: any, filename: string) {
        const file = new Blob([response], {
            type: response.type,
        });

        const nav = window.navigator as any;
        if (nav.navigator && nav.msSaveOrOpenBlob)
            return nav.msSaveOrOpenBlob(file);

        const blob = window.URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = blob;
        link.download = filename;

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        setTimeout(() => {
            window.URL.revokeObjectURL(blob);
            link.remove();
        }, 100);
    }

    onPageChange(event: any): void {
        this.returnedArray = this.projects.slice(
            event.first,
            event.first + event.rows
        );
    }
}
